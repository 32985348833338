import Logo from '@/components/icons/Logo';
import { Popover, Transition } from '@headlessui/react';
import Link from 'next/link';
import { Fragment, useEffect, useState } from 'react';
import DarkModeToggle from '@/components/utils/Widgets/DarkModeToggle';
import { Menu } from 'lucide-react';
import classNames from 'classnames';
import LandingButton from '../../Button/LandingButton';
import { useUser } from '@/utils/useUser';

const links = [
  {
    name: 'Home',
    href: '/'
  },
  {
    name: 'Platform',
    href: '/platform'
  },
  // {
  //   name: 'Signals',
  //   href: '/l/signals'
  // },
  {
    name: 'API',
    href: '/l/api'
  },
  {
    name: 'Pricing',
    href: '/pricing'
  },

  {
    name: 'Contact',
    href: '/contact'
  }
];

export default function Header() {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const { user } = useUser();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible = prevScrollPos > currentScrollPos || currentScrollPos <= 0;
      setIsVisible(visible);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos, isVisible, setIsVisible]);

  return (
    <header className="sticky top-0 z-50">
      <Popover
        className={classNames(
          'absolute z-40 w-full  bg-white dark:bg-black  transition-all duration-300	 ease-in-out	 ',
          {
            // 'translate-y-0': isVisible,
            // '-translate-y-full': !isVisible,
            'bg-opacity-0 dark:bg-opacity-0 shadow-black/0 border-b border-transparent':
              prevScrollPos < 10,
            'bg-opacity-100 dark:bg-opacity-60 shadow-black/10 border-b border-line  backdrop-blur-md ':
              prevScrollPos >= 10
          }
        )}
        // style={{ transform: isVisible ? 'translateY(0)' : 'translateY(-100%)' }}
      >
        <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-2 sm:px-6 md:justify-start md:space-x-10 lg:px-2 ">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link legacyBehavior href="/">
              <a>
                <Logo width={50} />
              </a>
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button
              className={classNames(
                ' rounded-md p-2 inline-flex items-center justify-center  hover:text-gray-500 ',
                {
                  'text-black dark:text-white': true
                }
              )}
            >
              <span className="sr-only">Open menu</span>
              <Menu className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10 ">
            {links.map((li) => (
              <Link
                key={li.name}
                href={li.href}
                className={classNames(
                  ' opacity-90 tracking-tight font-medium   hover:opacity-100',
                  {
                    'text-black dark:text-white': prevScrollPos >= 0
                  }
                )}
              >
                {li.name}
              </Link>
            ))}
          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0 space-x-2">
            <DarkModeToggle
              className="nav-button-borderless"
              iconClassName={classNames({})}
            />

            <LandingButton
              title={user?.id ? 'Dashboard' : 'Sign Up'}
              href={user?.id ? '/home' : '/signup'}
              small={true}
            />
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-0 ease-out"
          enterFrom="opacity-0 scale-50"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-50"
        >
          <Popover.Panel
            focus
            className="absolute z-40 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-opacity-5 panel  ">
              <div className="py-6 px-5">
                <div className="mt-6 flex flex-col justify-center items-center">
                  <Link href={'/signup'} className="mb-5 block text-center">
                    Sign Up
                  </Link>
                  <LandingButton title={' Get Started'} href={'/home'} />
                  {links.map((li) => (
                    <p
                      className="mt-6 text-center text-base font-medium text-primary"
                      key={li.href}
                    >
                      <Link
                        key={li.name}
                        href={li.href}
                        className=" opacity-90 tracking-wide   hover:opacity-100"
                      >
                        {li.name}
                      </Link>
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  );
}
